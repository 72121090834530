
// Format to number date of birth
export const formatDate = string => {
  if (string.length < 9) {
    const fragment = string.split("") || []
    const formated = fragment.reduce((acum, item, i) => {
      if ((i === 2 || i === 4) && item !== "/") acum.push("/")
      acum.push(item)
      return acum
    }, [])
    return formated.join("")
  }
  return ""
}

// Valida Emails
export const validateEmail = formEmail => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexEmail.test(String(formEmail).toLowerCase())
}