import React from "react"
import SEO from "../../components/seo"
import { ParticipateForm } from "../../components/Sunsets2023/ParticipateForm"

const ParticipatePage = () => {
  return (
    <div>
      <SEO title="SUNSETS SESSIONS 2023" />
      <ParticipateForm />
    </div>
  )
}

export default ParticipatePage
